// shadows
$blackest: #262626;

// background
$black: #2b2b2b;

// dark text
$dark-gray: #444;

// line rules
$subtle-gray: #333;

// main text
$gray: #919191;

// white
$white: #fff;

// good green
$green: #8db955;

// bad red orange
$red-orange: #d66a4c;

// error red
$error-red: #e05e5e;
