.animated {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-transform: translateZ(0);
}

@-webkit-keyframes shake {
    0%, 100% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }

    20%, 60% {
        -webkit-transform: translate3d(-10px, 0, 0);
                transform: translate3d(-10px, 0, 0);
    }

    40%, 80% {
        -webkit-transform: translate3d(10px, 0, 0);
                transform: translate3d(10px, 0, 0);
    }
}

@keyframes shake {
    0%, 100% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }

    20%, 60% {
        -webkit-transform: translate3d(-10px, 0, 0);
                transform: translate3d(-10px, 0, 0);
    }

    40%, 80% {
        -webkit-transform: translate3d(10px, 0, 0);
                transform: translate3d(10px, 0, 0);
    }
}

.shake {
    -webkit-animation-name: shake;
            animation-name: shake;
}

@-webkit-keyframes bounceOutLeft {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(20px, 0, 0);
                transform: translate3d(20px, 0, 0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
                transform: translate3d(-2000px, 0, 0);
    }
}

@keyframes bounceOutLeft {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(20px, 0, 0);
                transform: translate3d(20px, 0, 0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
                transform: translate3d(-2000px, 0, 0);
    }
}

.bounceOutLeft {
    -webkit-animation-name: bounceOutLeft;
            animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceInRight {
    0%, 60%, 75%, 90%, 100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
                transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0);
                transform: translate3d(3000px, 0, 0);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
                transform: translate3d(-25px, 0, 0);
    }

    75% {
        -webkit-transform: translate3d(10px, 0, 0);
                transform: translate3d(10px, 0, 0);
    }

    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
                transform: translate3d(-5px, 0, 0);
    }

    100% {
        -webkit-transform: none;
                transform: none;
    }
}

@keyframes bounceInRight {
    0%, 60%, 75%, 90%, 100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
                transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0);
                transform: translate3d(3000px, 0, 0);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
                transform: translate3d(-25px, 0, 0);
    }

    75% {
        -webkit-transform: translate3d(10px, 0, 0);
                transform: translate3d(10px, 0, 0);
    }

    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
                transform: translate3d(-5px, 0, 0);
    }

    100% {
        -webkit-transform: none;
                transform: none;
    }
}

.bounceInRight {
    -webkit-animation-name: bounceInRight;
            animation-name: bounceInRight;
}
